body,
html {
  height: 100%;
  font-size: 12px;
  color: @tLevel3;
  #root {
    height: 100%;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
dd,
dl,
li,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none outside none;
}

img {
  width: 100%;
}
.cf:after,
.cf:before {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  *zoom: 1;
}
//清除浮动
.clear:after {
  content: "";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.ant-layout {
  background: inherit !important;
}
.ant-layout .ant-layout-header{
  background: #fff !important;
}

.ant-layout-header{
  padding: 0;
  .ant-menu{
    max-width: 1240px;
    margin: 0 auto;
    font-size: 16px;
  }

  .ant-menu-item{
    width: 150px;
    text-align: center;
    font-size: 16px;
  }
}

.mt-8 {
  margin-top: 8px
}

.mt-16 {
  margin-top: 16px
}

.mt-24 {
  margin-top: 24px
}


.mb-8 {
  margin-bottom: 8px
}

.mb-16 {
  margin-bottom: 16px
}

.mb-24 {
  margin-bottom: 24px
}

.line-ellipsis{
  white-space:nowrap;        // 强制一行显示
  overflow:hidden;          // 超出隐藏
  text-overflow:ellipsis;   // 省略号
}

.line-ellipsis2{
  overflow: hidden;				//溢出内容隐藏
  text-overflow: ellipsis;		//文本溢出部分用省略号表示
  display: -webkit-box;			//特别显示模式
  -webkit-line-clamp: 2;			//行数
  line-clamp: 2;
  -webkit-box-orient: vertical;	//盒子中内容竖直排列
}

.ant-layout {
  height: 100%;
}

.site-layout-content {
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
  padding: 16px;
  background: rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.ant-layout .ant-layout-footer {
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  color: #666 !important;
  background: rgba(255,255,255,.8);
}

.ant-card-body {
  padding: 16px;
}

.double-title{
  font-size: 16px;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-bottom: 8px;

  &:after {
    content: "";
    background-color: #0084ff;
    left: 1px;
    width: 34px;
    height: 2px;
    bottom: -1px;
    position: absolute;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
  }
}

.double-list:hover {
  .double-title::after{
    width: 60px
  }
}
.ant-empty{
  margin: 8px;
  .ant-empty-description{
    color: #ccc;
  }
}

@primary-color: #0084ff;@tLeve1: #333;@tLevel2: #666;@tLevel3: #9b9b9b;@body-bg: #f9f9fe;