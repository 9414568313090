.loading {
  padding: 20px 0;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  transform: translateX(-50%) translateY(-50%);
  z-index: 11;
}

@primary-color: #0084ff;@tLeve1: #333;@tLevel2: #666;@tLevel3: #9b9b9b;@body-bg: #f9f9fe;