
// 脉冲
#pulse {
  -webkit-animation: pulse 1s .2s ease both;
  -moz-animation: pulse 1s .2s ease both;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform:scale(1)
  }
  50% {
    -webkit-transform:scale(1.1)
  }
  100% {
    -webkit-transform:scale(1)
  }
}
@-moz-keyframes pulse {
  0% {
    -moz-transform:scale(1)
  }
  50% {
    -moz-transform:scale(1.1)
  }
  100% {
    -moz-transform:scale(1)
  }
}


/*========== 图片放大transform =========*/
.enlargement img {
  -webkit-transition: all .4s;
  transition: all .4s
}

.enlargement .list:hover img {
  border-radius: 0;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -ms-transform: scale(1.2)
}

@primary-color: #0084ff;@tLeve1: #333;@tLevel2: #666;@tLevel3: #9b9b9b;@body-bg: #f9f9fe;